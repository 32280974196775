<template>
  <div class="page-box" ref='page'>
    <a-spin tip="Loading..." :spinning="formLoading">
      <a-form-model
        :loading="formLoading"
        ref="ruleForm"
        :model="form"
        :rules="isView?{}:rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item label="活动名称：" prop="activityName">
          <a-input
            v-if="!isView"
            v-model="form.activityName"
            style="width: 300px"
            allowClear
            placeholder="活动名称"
          />
          <div v-else>{{form.activityName}}</div>
        </a-form-model-item>
        <a-form-model-item label="活动时间：" prop="activityDate">
          <div v-if="!isView">
            <a-date-picker
              v-model="form.beginTime"
              :disabled-date="disabledStartDate"
              show-time
              :disabled="form.state !== 0"
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="开始时间"
              @change="changeDate"
            /> 至
            <a-date-picker
              @change="changeDate"
              v-model="form.endTime"
              :disabled-date="disabledEndDate"
              :disabled-time="disabledEndDateTime"
              :disabled="!form.beginTime"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="结束时间"
            />
          </div>
          <div v-else>{{moment(form.beginTime).format('YYYY-MM-DD HH:mm:ss')}} 至 {{moment(form.endTime).format('YYYY-MM-DD HH:mm:ss')}}</div>
        </a-form-model-item>
        <a-form-model-item label="砍价商品：" prop="productId">
          <span v-if="selectProductList.length" style='font-size:16px;font-weight:900;'>{{selectProductList[0].productName}}</span>
          <a-button type="link" v-if="!isView&&!activityId" @click="selectProduct">{{selectProductList.length?'重选商品':'选择商品'}}</a-button>
          <a-button type="link" @click="deletSelectProduct" v-if="!isView&&!activityId&&selectProductList.length">删除</a-button>

          <a-button type="link" v-if="selectProductList.length" @click='hideTable = !hideTable'>{{hideTable?'展开':'收起'}}</a-button>
          <div class='tips'>注：商品规格中最低零售价需大于最高成本价</div>
          <a-table
            bordered
            :pagination="false"
            v-if="selectProductList.length && !hideTable"
            :columns="columns1"
            :rowKey="record => record.productSkuId"
            :dataSource="selectProductList">
            <template slot="name" slot-scope="text,record">
              <div class="good-name-box">
                <base-img
                  :src="record.skuPic||record.productImg||record.mainPicPath"
                  style="flex-shrink:0;margin-right:8px;"
                  width="60"
                  height="60"
                ></base-img>
                <div class="good-name">
                  {{record.productName}}<span v-if="record.skuName">规格：{{record.skuName}}</span>
                </div>
              </div>
            </template>
            <template slot="operation" v-if="!isView">
              <a-button type="link" style="padding:0" @click="deletSelectProduct"></a-button>
            </template>
          </a-table>
        </a-form-model-item>
        <a-form-model-item label="砍价有效期：" prop="validHour">
          <a-input
            v-if="!isView"
            v-model="form.validHour"
            :disabled='form.state !== 0'
            style="width: 160px"
            addon-after="小时"
            placeholder="请输入"
          />
          <div v-else>{{form.validHour}}小时</div>
        </a-form-model-item>
        <a-form-model-item label="砍价最低价：" prop="minPrice">
          <a-input
            v-if="!isView"
            addon-before="¥"
            :disabled="!form.productId||form.state !== 0"
            v-model="form.minPrice"
            style="width: 160px"
            placeholder="请输入"
          />
          <div v-else>{{form.minPrice}}元</div>
        </a-form-model-item>
        <a-form-model-item label="活动商品库存：" prop="activityStock">
          <a-input
            v-if="!isView"
            addon-after="件"
            :disabled="!form.productId||form.state !== 0"
            v-model="form.activityStock"
            style="width: 160px"
            placeholder="请输入"
          />
          <div v-else>{{form.activityStock}}件</div>
        </a-form-model-item>
        <a-form-model-item label="帮砍金额：" :required="!isView" prop="helpAmountType">
          <a-radio-group v-if="!isView" :disabled='form.state !== 0' name="helpAmountType" v-model="form.helpAmountType">
            <a-radio :value="0">随机</a-radio>
            <a-radio :value="1">固定</a-radio>
          </a-radio-group>
          <div v-else>{{form.helpAmountType===0?'随机':'固定'}}</div>
        </a-form-model-item>
        <a-form-model-item label="帮砍人数：" prop="helpPeopleNumber">
          <a-input
            v-if="!isView"
            v-model="form.helpPeopleNumber"
            addon-after="人"
            :disabled='form.state !== 0'
            style="width: 160px"
            allowClear
            placeholder="2-100人"
          />
          <div v-else>{{form.helpPeopleNumber}}人</div>
          <p class='tips'>注：保证人均砍价不得低于1分钱</p>
        </a-form-model-item>
        <a-form-model-item label="帮砍次数：" prop="helpNumber">
          <a-input
            v-if="!isView"
            :disabled='form.state !== 0'
            v-model="form.helpNumber"
            addon-after="次"
            style="width: 160px"
            allowClear
            placeholder="请输入"
          />
          <div v-else>{{form.helpNumber}}次</div>
          <div class='tips'>注：每人最多帮砍次数</div>
        </a-form-model-item>
        <a-form-model-item label="购买设置" :required="!isView" prop="buyType">
          <a-radio-group :disabled='form.state !== 0' v-if="!isView" name="buyType" v-model="form.buyType">
            <a-radio :value="1">砍到最低价购买</a-radio>
            <a-radio :value="2">最低价前均可购买</a-radio>
          </a-radio-group>
          <div v-else>{{form.buyType===1?'砍到最低价购买':'最低价前均可购买'}}</div>
        </a-form-model-item>
        <a-form-model-item label="发起砍价次数：" prop="barginLimitNumber">
          <a-input
            v-if="!isView"
            v-model="form.barginLimitNumber"
            addon-after="次"
            style="width: 160px"
            :disabled='form.state !== 0'
            allowClear
            placeholder="请输入"
          />
          <div v-else>{{form.barginLimitNumber}}次</div>
        </a-form-model-item>

        <a-row type="flex" align="middle" justify="center" v-if='!isView'>
          <a-button type="primary" @click="onSubmit" :loading="saveBtnLoading">保存</a-button>
          <a-button style="margin-left: 10px;" @click="closePage">取消</a-button>
        </a-row>
      </a-form-model>
    </a-spin>
    <a-modal
      title="选择商品"
      :visible="modalShow"
      width="800px"
      @cancel="modalCancel"
      @ok="modalConfirm"
      :getContainer="()=>$refs.page">
      <div class="search-box">
        <a-input placeholder="搜索商品名称" style="width: 200px;" v-model="productName"></a-input>
        <a-button type="primary" style="margin:0 16px;" @click="selectProduct">搜索</a-button>
        <a-button @click="resetSearch">重置</a-button>
      </div>
      <a-table
        bordered
        :columns="columns"
        :dataSource="productList"
        :pagination="pagination"
        :rowKey="record => record.productId"
        :loading="modalLoading"
        :row-selection="{ type:'radio',selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps  }"
        @change="handleTableChange"
      >
        <template slot="name" slot-scope="text,record">
          <div class="good-name-box">
            <base-img
              :src="record.productImg"
              style="flex-shrink:0;margin-right:8px;"
              width="60"
              height="60"
            ></base-img>
            <div class="good-name">
              <a-button
                class="name"
                type="link"
                @click="goGoodDetail(record.productId)"
              >{{record.name}}</a-button>
              <div style="color:#ED6A0C;">¥{{record.price}}</div>
            </div>
          </div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from '@vue/composition-api'
import moment from 'moment'
import { activity } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { activityTypes } from '@/utils/constants'
import { checkInteger } from '@/utils/validator'
export default {
  name: 'PageGroupActivityAdd',
  setup (props, { root }) {
    // modal
    const columns = [
      {
        title: '商品名称',
        dataIndex: 'name',
        width: 300,
        scopedSlots: { customRender: 'name' }
      },
      {
        title: '库存',
        dataIndex: 'stock'
      },
      {
        title: '销量',
        dataIndex: 'sales'
      },
      {
        title: '已参与活动名称',
        dataIndex: 'activityName',
        customRender: x => {
          return x || '-'
        }
      },
      {
        title: '不可选原因',
        dataIndex: 'activityType',
        customRender: (x, record) => {
          if (x) {
            return (
              '该商品正在参加' +
              activityTypes.find(item => item.value === Number(x)).name +
              '活动'
            )
          } else {
            return '-'
          }
        }
      }
    ]
    // 商品
    const columns1 = [
      {
        title: '商品名称',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' }
      },
      {
        title: '零售价',
        dataIndex: 'price',
        scopedSlots: { customRender: 'price' }
      },
      {
        title: '成本价',
        dataIndex: 'supplyPrice',
        scopedSlots: { customRender: 'supplyPrice' }
      },
      {
        title: '库存',
        dataIndex: 'stock',
        scopedSlots: { customRender: 'stock' }
      }
    ]
    const labelCol = { span: 3 }
    const wrapperCol = { span: 20 }
    const rules = {
      activityName: [
        { required: true, message: '请输入活动名称', trigger: 'change' },
        { max: 32, message: '请尝试简短一些的活动名称', trigger: 'change' }
      ],
      activityDate: [
        { required: true, message: '请选择活动时间', trigger: 'change' },
        {
          validator: (rule, value, callback) => {
            if (!state.form.beginTime || !state.form.endTime) {
              callback(new Error('请选择活动时间'))
            } else {
              callback()
            }
          }
        }
      ],
      productId: [
        { required: true, message: '请选择活动商品', trigger: 'change' }
      ],
      validHour: [
        { required: true, message: '请输入砍价有效期', trigger: 'change' },
        {
          validator: (rule, value, callback) => {
            if (value) {
              const reg = /^[0-9]*[1-9][0-9]*$/
              if (reg.test(value)) {
                if (Number(value) < 1 || Number(value) > 48) {
                  return callback(new Error('请输入1-48'))
                } else {
                  callback()
                }
              } else {
                return callback(new Error('请输入一个整数'))
              }
            } else {
              callback()
            }
          }
        }
      ],
      helpPeopleNumber: [
        { required: true, message: '请输入帮砍人数', trigger: 'change' },
        {
          validator: (rule, value, callback) => {
            if (value) {
              const reg = /^[0-9]*[1-9][0-9]*$/
              if (reg.test(value)) {
                if (Number(value) < 2 || Number(value) > 100) {
                  return callback(new Error('请输入2-100'))
                } else {
                  callback()
                }
              } else {
                return callback(new Error('请输入一个整数'))
              }
            } else {
              callback()
            }
          }
        }
      ],
      minPrice: [
        { required: true, message: '请输入砍价最低价', trigger: 'change' },
        {
          validator: (rule, value, callback) => {
            if (value) {
              let supplyPriceArr = state.selectProductList.map(x => x.supplyPrice)
              const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
              if (reg.test(value)) {
                if (Number(value) <= Math.max(...supplyPriceArr)) {
                  return callback(new Error('必须高于最高成本价' + Math.max(...supplyPriceArr) + '，原因：规格中最高价商品会亏本'))
                } else if (Number(value) >= Math.min.apply(null, state.selectProductList.map(item => item.price))) {
                  return callback(new Error('必须小于最低零售价：' + Math.min.apply(null, state.selectProductList.map(item => item.price)) + '，原因：规格中最低价商品无砍价空间。'))
                } else {
                  callback()
                }
              } else {
                return callback(new Error('整数或最多两位小数'))
              }
            } else {
              callback()
            }
          }
        }
      ],
      activityStock: [
        { required: true, message: '请输入活动商品库存', trigger: 'change' },
        {
          validator: (rule, value, callback) => {
            if (value) {
              const reg = /^[0-9]*[1-9][0-9]*$/
              if (reg.test(value)) {
                if (Number(value) > state.selectProductList.map(item => item.stock).reduce((prev, current, index, arr) => {
                  return Number(prev) + Number(current)
                })) {
                  return callback(new Error('不能超过商品总库存(总库存：' + state.selectProductList.map(item => item.stock).reduce((prev, current, index, arr) => {
                    return Number(prev) + Number(current)
                  }) + ')'))
                } else {
                  callback()
                }
              } else {
                return callback(new Error('请输入一个整数'))
              }
            } else {
              callback()
            }
          }
        }
      ],
      barginLimitNumber: [
        { required: true, message: '请输入发起砍价次数', trigger: 'change' },
        { validator: checkInteger }
      ],
      helpNumber: [
        { required: true, message: '请输入帮砍次数', trigger: 'change' },
        { validator: checkInteger }
      ]
    }
    onMounted(() => {
      if (root.$route.path.indexOf('/activity/bargain/detail/') !== -1) {
        state.isView = true
      } else {
        state.isView = false
      }
      state.activityId = root.$route.params.id || ''
      if (state.activityId) {
        getData()
      }
    })
    const state = reactive({
      hideTable: false,
      isView: false,
      activityId: '',
      formLoading: false,
      modalShow: false,
      modalLoading: false,
      saveBtnLoading: false,
      selectProductList: [],
      form: {
        helpAmountType: 0,
        buyType: 1,
        activityDate: '1',
        beginTime: null,
        endTime: null,
        state: 0
      },
      productList: [],
      ruleForm: null,
      productName: '',
      selectedRowKeys: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 5,
        total: 0,
        size: 'small'
      }
    })
    function onSubmit () {
      state.ruleForm.validate(async valid => {
        if (valid) {
          let params = Object.assign(
            { shopId: getSession(SHOPID) },
            state.form
          )
          params.beginTime = moment(state.form.beginTime).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          params.endTime = moment(state.form.endTime).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          params.skuMinPrice = Math.min.apply(null, state.selectProductList.map(item => item.price))
          state.saveBtnLoading = true
          let { code, msg } = await activity.saveBargainActivity(params)
          state.saveBtnLoading = false
          if (code === '00000') {
            root.$message.success('创建成功！')
            root.$bus.$emit('PageBargainList:refresh')
            this.closePage()
          } else {
            root.$message.error(msg || '创建失败！')
          }
        }
      })
    }
    function closePage () {
      if (
        root.$store.state.tabs.views
          .map(item => item.path)
          .indexOf('/activity/bargain') !== -1
      ) {
        root.$closeCurrentView()
      } else {
        root.$closeCurrentView('/activity/bargain')
      }
    }
    async function selectProduct () {
      state.modalShow = true
      state.modalLoading = true
      let {
        code,
        data,
        page,
        msg
      } = await activity.getActivitySelectProductpage({
        current: state.productName !== '' ? 1 : state.pagination.current,
        size: state.pagination.pageSize,
        name: state.productName
      })
      state.modalLoading = false
      if (code === '00000') {
        state.productList = data
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function handleTableChange ({ current }) {
      state.pagination.current = current
      selectProduct()
    }
    function onSelectChange (selectedRowKeys, selectedRows) {
      state.selectedRowKeys = selectedRowKeys
      state.selectedRows = selectedRows
    }
    function getCheckboxProps (record) {
      return {
        props: { disabled: !!record.activityType }
      }
    }
    function modalCancel () {
      state.selectedRowKeys = []
      state.selectedRows = []
      state.modalShow = false
    }
    function modalConfirm () {
      let supplyPriceArr = state.selectedRows[0].skuList.map(x => x.supplyPrice)
      let priceArr = state.selectedRows[0].skuList.map(x => x.price)
      if (Math.max(...supplyPriceArr) >= Math.min(...priceArr)) {
        return root.$message.error('该商品不能参加砍价活动，原因：商品规格最低零售价需大于最高成本价')
      }
      state.selectProductList = state.selectedRows[0].skuList.map(item => {
        item.productImg = state.selectedRows[0].productImg
        item.productName = state.selectedRows[0].name
        return item
      })
      root.$set(state.form, 'productId', state.selectedRows[0].productId)
      state.modalShow = false
    }
    function goGoodDetail (productId) {
      root.$router.push(
        `/goods/goods_detail/${productId}`
      )
    }
    function deletSelectProduct () {
      state.selectedRowKeys = []
      state.selectedRows = []
      state.selectProductList = []
    }
    function resetSearch () {
      state.productName = ''
      selectProduct()
    }
    async function getData () {
      let { code, data, msg } = await activity.getBargainActivityDetail({
        barginActivityId: state.activityId
      })
      if (code === '00000') {
        state.form = {
          ...data,
          activityDate: '1',
          beginTime: moment(data.beginTime),
          endTime: moment(data.endTime)
        }
        state.selectProductList = data.skuList.map(item => {
          item.mainPicPath = data.productMainPic
          item.productName = data.productName
          return item
        })
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function disabledStartDate (startValue) {
      const endValue = moment(state.form.endTime)
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    }
    function disabledEndDate (endValue) {
      const startValue = moment(state.form.beginTime)
      const nowValue = moment(new Date())
      if (!endValue || !startValue) {
        return false
      }
      if (nowValue.valueOf() > startValue.valueOf()) {
        return (nowValue.valueOf() - 24 * 3600 * 1000) >= endValue.valueOf()
      } else {
        return startValue.valueOf() > endValue.valueOf()
      }
    }
    function range (start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }
    function disabledEndDateTime () {
      // 结束时间 比开始时间大于 1天，时分秒没有限制
      if (state.form.endTime && (new Date(state.form.endTime).getTime() - new Date(state.form.beginTime).getTime()) > 24 * 3600 * 1000) {
        return {}
      } else {
        const startValue = moment(state.form.beginTime)
        const nowValue = moment(new Date())
        let dateTime = ''
        if (nowValue.valueOf() >= startValue.valueOf()) {
          dateTime = new Date()
        } else {
          dateTime = state.form.beginTime
        }
        return {
          disabledHours: () => range(0, new Date(dateTime).getHours()),
          disabledMinutes: () => {
            if (state.form.endTime && new Date(state.form.endTime).getHours() > new Date(dateTime).getHours()) {
              return []
            } else {
              return range(0, new Date(dateTime).getMinutes())
            }
          },
          disabledSeconds: () => {
            if (state.form.endTime && new Date(state.form.endTime).getHours() > new Date(dateTime).getHours()) {
              return []
            } else if (state.form.endTime && new Date(state.form.endTime).getHours() === new Date(dateTime).getHours() && new Date(state.form.endTime).getMinutes() > new Date(dateTime).getMinutes()) {
              return []
            } else {
              return range(0, new Date(dateTime).getSeconds())
            }
          }
        }
      }
    }
    function changeDate () {
      state.ruleForm.validateField('activityDate')
    }
    return {
      disabledStartDate,
      disabledEndDate,
      labelCol,
      wrapperCol,
      rules,
      moment,
      columns,
      columns1,
      ...toRefs(state),
      getData,
      onSubmit,
      selectProduct,
      handleTableChange,
      onSelectChange,
      getCheckboxProps,
      modalCancel,
      modalConfirm,
      goGoodDetail,
      deletSelectProduct,
      resetSearch,
      closePage,
      changeDate,
      disabledEndDateTime
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .good-name-box {
  display: flex;
  align-items: center;
  width: 300px;
  .good-name {
    min-height: 60px;
    width: 232px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .name {
      padding: 0;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
    }
  }
}
.group-price-form {
  ::v-deep .ant-form-item {
    margin-bottom: 0;
  }
}
.batch-box {
  display: flex;
  align-items: center;
}
.search-box {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: flex-end;
}
::v-deep .ant-form-item {
  margin-bottom: 12px;
}
.sku-box {
  display: flex;
  align-items: center;
  div {
    padding: 0 12px;
  }
}
.tips {
  font-size: 12px;

}
</style>
